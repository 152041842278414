<template>
  <b-container id="login">
    <b-form-row>
      <b-col cols="12" md="8" offset-md="2">
        <b-form>
          <b-form-group
            label="Email"
            label-for="dropdown-form-email"
            @submit.stop.prevent
          >
            <b-form-input
              id="dropdown-form-email"
              size="sm"
              placeholder="user@3dagency.com"
              v-model="email"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Password" label-for="dropdown-form-password">
            <b-form-input
              id="dropdown-form-password"
              type="password"
              size="sm"
              placeholder="Password"
              v-model="password"
              @keydown.enter.native="doLogin"
            ></b-form-input>
          </b-form-group>
          <b-button variant="primary" size="sm" @click="doLogin"
            >Sign In</b-button
          >
        </b-form>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import { getHomeForRole } from "@/util";
import { defaultToastBody, defaultToastConfig } from "@/util";

export default {
  name: "Login",
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    isLoggedIn: () => {
      this.$store.state.user;
    },
  },
  methods: {
    doLogin() {
      let payload = { username: this.email, password: this.password };
      this.$store
        .dispatch("login", payload)
        .then(() => {
          let currentRole = this.$store.state.user.role;
          let route = getHomeForRole(currentRole);
          this.$router.push(route);
        })
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
  },
};
</script>